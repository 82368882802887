import '@/api/config'
import '@/styles/global.css'
import '@/styles/reset.css'
import 'react-loading-skeleton/dist/skeleton.css'

import queryKeys from '@/constants/queryKeys'
import { routes } from '@/constants/routes'
import AgreementCheckContainer from '@/containers/AgreementCheckContainer'
import AuthenticationProvider from '@/containers/AuthenticationProvider'
import BodyLockedProvider from '@/containers/BodyLockedProvider'
import DialogProvider from '@/containers/DialogProvider'
import GlobalLayoutContainer from '@/containers/GlobalLayoutContainer'
import HeaderVisibilityProvider from '@/containers/HeaderVisibilityProvider'
import ToastMessageProvider from '@/containers/ToastMessageProvider'
import VerifiedEmailCheckContainer from '@/containers/VerifiedEmailCheckContainer'
import useAmplitudeInitialize from '@/hooks/useAmplitudeInitialize'
import theme from '@/theme'
import antdTheme from '@/theme/antdThemeConfig'
import { getErrorResponse } from '@/utils'
import { defaultQueriesOptions } from '@/utils/queryOptions'
import { ConfigProvider } from 'antd'
import { isAxiosError } from 'axios'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Fragment, useState } from 'react'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider } from 'theme-ui'

import type { AppProps } from 'next/app'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: { queries: defaultQueriesOptions },
        queryCache: new QueryCache({
          onError: (error: any) => {
            // TODO GraphQL 拔掉後，可以拔掉 getErrorResponse
            const graphqlErrorResponse = getErrorResponse(error)

            const axiosStatusCode = isAxiosError(error) && error.response?.status
            const graphqlStatusCode = graphqlErrorResponse?.statusCode
            const isUnauthorized = axiosStatusCode === 401 || graphqlStatusCode === 401

            if (isUnauthorized) {
              if (queryClient.getQueryData([queryKeys.ME])) {
                queryClient.resetQueries({ queryKey: [queryKeys.ME], type: 'all' })
              }
            }

            // TODO 改成 v2 以後，要確認需要導頁到 404 的錯誤要怎麼判斷
            if (
              graphqlStatusCode === 404 &&
              graphqlErrorResponse?.message.toLowerCase() === 'not found' &&
              location.pathname !== routes.NOT_FOUND
            ) {
              // 404處理
              router.replace(routes.NOT_FOUND)
            }
          },
        }),
      })
  )
  useAmplitudeInitialize()

  return (
    <Fragment>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
      </Head>

      <ThemeProvider theme={theme}>
        <ConfigProvider theme={antdTheme}>
          <QueryClientProvider client={queryClient}>
            <BodyLockedProvider>
              <ToastMessageProvider>
                <DialogProvider>
                  <AuthenticationProvider>
                    <VerifiedEmailCheckContainer>
                      <AgreementCheckContainer>
                        <HeaderVisibilityProvider>
                          <GlobalLayoutContainer>
                            <Component {...pageProps} />
                          </GlobalLayoutContainer>
                          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                        </HeaderVisibilityProvider>
                      </AgreementCheckContainer>
                    </VerifiedEmailCheckContainer>
                  </AuthenticationProvider>
                </DialogProvider>
              </ToastMessageProvider>
            </BodyLockedProvider>
          </QueryClientProvider>
        </ConfigProvider>
      </ThemeProvider>
    </Fragment>
  )
}

export default MyApp
