import { getChapterReadingTicketSettings } from '@/api/queryFn'
import { ErrorV2 } from '@/api/types/errors'
import queryKeys from '@/constants/queryKeys'
import { queryOptions } from '@/utils/queryOptions'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export function useGetChapterReadingTicketSettingsQuery<
  TData = Awaited<ReturnType<typeof getChapterReadingTicketSettings>>,
  TError = AxiosError<ErrorV2>
>(
  titleId: number,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getChapterReadingTicketSettings>>,
    TError,
    TData
  >
) {
  return useQuery({
    queryKey: [queryKeys.CHAPTER_READING_TICKET_SETTINGS, { titleId }],
    queryFn: () => getChapterReadingTicketSettings(titleId),
    ...queryOptions.useGetChapterReadingTicketSettingsQuery,
    ...options,
  })
}
