import { getMyOverview } from '@/api/queryFn'
import queryKeys from '@/constants/queryKeys'
import { queryOptions } from '@/utils/queryOptions'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

/**
 * @name 取得我的總覽
 */
export function useGetMyOverviewQuery<
  TData = Awaited<ReturnType<typeof getMyOverview>>,
  TError = AxiosError
>(options?: UseQueryOptions<Awaited<ReturnType<typeof getMyOverview>>, TError, TData>) {
  return useQuery({
    queryKey: [queryKeys.MY_OVERVIEW],
    queryFn: getMyOverview,
    ...queryOptions.useGetMyOverviewQuery,
    ...options,
  })
}
