import { getGenres } from '@/api/queryFn'
import queryKeys from '@/constants/queryKeys'
import { queryOptions } from '@/utils/queryOptions'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export function useGetGenresQuery<
  TData = Awaited<ReturnType<typeof getGenres>>,
  TError = AxiosError
>(
  params: { isRestricted: boolean },
  options?: UseQueryOptions<Awaited<ReturnType<typeof getGenres>>, TError, TData>
) {
  return useQuery({
    queryKey: [queryKeys.GENRES, params],
    queryFn: () => getGenres(params),
    ...queryOptions.useGetGenresQuery,
    ...options,
  })
}
