import { getChapterSettingById } from '@/api/queryFn'
import { ErrorV2 } from '@/api/types/errors'
import queryKeys from '@/constants/queryKeys'
import { queryOptions } from '@/utils/queryOptions'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export function useGetChapterSettingByIdQuery<
  TData = Awaited<ReturnType<typeof getChapterSettingById>>,
  TError = AxiosError<ErrorV2>
>(
  chapterId: number,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getChapterSettingById>>, TError, TData>
) {
  return useQuery({
    queryKey: [queryKeys.CHAPTER_SETTING, { chapterId }],
    queryFn: () => getChapterSettingById(chapterId),
    ...queryOptions.useGetChapterSettingByIdQuery,
    ...options,
  })
}
