import { getReceivedGifts } from '@/api/queryFn'
import { PaginationParams, ReceivedGiftCondition } from '@/api/types'
import queryKeys from '@/constants/queryKeys'
import { queryOptions } from '@/utils/queryOptions'
import { AxiosError } from 'axios'
import { useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query'

/**
 * @name 送禮通知列表
 */
export function useInfiniteGetReceivedGiftsQuery<
  TData = Awaited<ReturnType<typeof getReceivedGifts>>,
  TError = AxiosError
>(
  params: ReceivedGiftCondition & PaginationParams,
  options?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getReceivedGifts>>, TError, TData>
) {
  return useInfiniteQuery({
    queryKey: [queryKeys.RECEIVED_GIFTS, 'infinite', params],
    queryFn: ({ pageParam = params.current }) =>
      getReceivedGifts({ ...params, current: pageParam }),
    getNextPageParam: lastPage => (lastPage.more ? lastPage.current + 1 : undefined),
    ...queryOptions.useInfiniteGetReceivedGiftsQuery,
    ...options,
  })
}
