import { getMe, getVerifiedAuthorStatus } from '@/api/queryFn'
import { ErrorV2 } from '@/api/types/errors'
import queryKeys from '@/constants/queryKeys'
import { queryOptions } from '@/utils/queryOptions'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export function useGetMeQuery<
  TData = Awaited<ReturnType<typeof getMe>>,
  TError = AxiosError<ErrorV2>
>(options?: UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>) {
  return useQuery({
    queryKey: [queryKeys.ME],
    queryFn: getMe,
    ...queryOptions.useGetMeQuery,
    ...options,
  })
}

export function useGetVerifiedAuthorStatusQuery<
  TData = Awaited<ReturnType<typeof getVerifiedAuthorStatus>>,
  TError = AxiosError<ErrorV2>
>(options?: UseQueryOptions<Awaited<ReturnType<typeof getVerifiedAuthorStatus>>, TError, TData>) {
  return useQuery({
    queryKey: [queryKeys.VERIFIED_AUTHOR_STATUS],
    queryFn: getVerifiedAuthorStatus,
    ...queryOptions.useGetVerifiedAuthorStatusQuery,
    ...options,
  })
}
