import { getContestById, getContests } from '@/api/queryFn'
import { ErrorV2 } from '@/api/types/errors'
import queryKeys from '@/constants/queryKeys'
import { queryOptions } from '@/utils/queryOptions'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export function useGetContestsQuery<
  TData = Awaited<ReturnType<typeof getContests>>,
  TError = AxiosError<ErrorV2>
>(options?: UseQueryOptions<Awaited<ReturnType<typeof getContests>>, TError, TData>) {
  return useQuery({
    queryKey: [queryKeys.CONTESTS],
    queryFn: getContests,
    ...queryOptions.useGetContestsQuery,
    ...options,
  })
}

export function useGetContestByIdQuery<
  TData = Awaited<ReturnType<typeof getContestById>>,
  TError = AxiosError<ErrorV2>
>(
  contestId: number,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getContestById>>, TError, TData>
) {
  return useQuery({
    queryKey: [queryKeys.CONTESTS_DETAIL, { contestId }],
    queryFn: () => getContestById(contestId),
    ...queryOptions.useGetContestByIdQuery,
    ...options,
  })
}
