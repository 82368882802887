import { ListingStatus } from '@/api/types'
import dayjs from 'dayjs'

export const getTitleListingStatus = (
  visible?: boolean,
  listingFrom?: string
): ListingStatus | undefined => {
  if (visible === undefined || listingFrom === undefined) return undefined
  if (!!visible) {
    return dayjs().isAfter(listingFrom) ? ListingStatus.LISTED : ListingStatus.SCHEDULED
  } else {
    return ListingStatus.UNLISTED
  }
}
